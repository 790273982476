.side-2 {
	.block {
		.title {
			.fvr & {
				background-color: $green;
				color: $white;
				border-radius: .5rem;
			}
			&::after {
				.fvr & {
					background: url('../../FvrAssets/img/arrow-drop-big.svg') no-repeat center center;
				}
			}
		}
	}

	.filtersGroupPanel {
		@include padding($short: 2.5rem 1rem 1rem);
		.checkbox-list {
			label {
				&::before {
					.fvr & {
						border-radius: 0;
						border: .1rem solid $green;
						@include size(1.2rem);
						transform: translateY(-50%);
						@include margin($short: 0);
						background: none;
					}
				}
			}
			li {
				&.checked {
					label {
						&::after {
							content: '';
							@include absolute(top 50% left 14px);
							transform: translateY(-50%) rotate(45deg);
							@include size(.4rem, .7rem);
							border-bottom: .2rem solid $green;
							border-right: .2rem solid $green;
						}
					}
				}
			}
		}
	}
	.colorOptions {
		.checked {
			label {
				&::before {
					.fvr & {
						background-image: none!important;
					}
				}
			}
		}
	}
}
