.product {
	&-selectors {
		.fvr & {
			background-color: $green;
			border-radius: .5rem;
			@include padding($short: .8rem 3rem);
		}
	}
	&-viewmode {
		a {
			&.grid {
				.fvr & {
					background-image: url('../../FvrAssets/img/grid.svg');
					background-position: center;
				}
			}

			&.list {
				.fvr & {
					background-image: url('../../FvrAssets/img/list.svg');
					background-position: center;
				}
			}

		}
	}

	&-sorting {
		.fvr & {
			@extend %flex-wrap;
			justify-content: flex-end;
			align-items: center;
			@include margin($short: 0);
		}
		> span {
			.fvr & {
				color: $white;
				text-transform: none;
				@include margin($right: 1rem);
			}
		}

		select {
			.fvr & {
				height: 3.4rem;
				min-width: 21rem;
				border: none;
				@include padding($short: .5rem 3.5rem .5rem 2rem);
			}
		}

		.select-box {
			.fvr & {
				height: 3.4rem;
				border-radius: .5rem;
				border: none;
				@include padding($short: .5rem 3.5rem .5rem 2rem);
				@extend %flex-center-v;
			}
			&::after {
				.fvr & {
					background: url('../../FvrAssets/img/arrow-drop-big-g.svg') no-repeat center center;
				}
			}
		}
	}

	&-item {
		text-align: center;
	}

	&-title {
		a {
			.fvr & {
				color: $gray;
			}
		}
	}

	&-list {
		.quick-view-button {
			.fvr & {
				background-color: $green;
			}
		}
		.item-box {
			.details {
				.fvr & {
					@include bp('medium') {
						@include padding($short: 0 0 7rem);
					}
				}
			}
		}
	}

	&-grid {
		&.nosidebar {
			@include bp('extra-large') {
				@include margin($short: 0 -11rem);
				@include padding($short: 0 2rem);
			}

			@include bp('wide') {
				@include padding($short: 0);
				@include margin($short: 0);
			}
		}

		.title {
			strong {
				&::after {
					.earth-theme.fvr & {
						content: none;
					}
				}
			}
		}
	}

	&-social {
		&-buttons {
			.fvr & {
				border-top: none;
				background-color: transparent;

				@include bp('medium') {
					width: 30%;
					margin: 0;
					float: right;
					border-top: .1rem solid #eee;
					@include padding($short: 2rem 0);
					height: 8.6rem;
					@include _('flex-center-v')
				}

				@include bp('extra-large') {
					width: 45%;
				}

				@include bp('wide') {
					width: 35%;
				}
			}

			label {
				.fvr & {
					color: $green;
					@include font-size(12);
					font-weight: 700;
					text-transform: uppercase;
					@include margin($short: 0);
					display: block;
				}
			}
			li {
				.fvr & {
					@include margin($short: 0 1rem);
				}
				a {
					.fvr & {
						background-image: none;
						background-position: center;
					}
				}
				&.twitter {
					a {
						.fvr & {
							background-image: url('../../FvrAssets/img/twitter.svg');
						}
					}
				}
				&.facebook {
					a {
						.fvr & {
							background-image: url('../../FvrAssets/img/facebook.svg');
						}
					}
				}
				&.instagram {
					a {
						.fvr & {
							background-image: url('../../FvrAssets/img/instagram.svg');
						}
					}
				}
			}
		}
	}

	&-name {
		&-desktop {
			display: none;
			@include bp('extra-large') {
				display: block;
			}
		}

		&-tablet {
			display: block;
			@include bp('extra-large') {
				display: none;
			}
		}
	}

	&-price {
		&-label {
			.prices & {
				.product-details-page & {
					display: block;
				}
			}
		}
	}
}