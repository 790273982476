.media {
	text-align: left;

	@include bp('medium') {
		display: flex;
	}

	&-reverse {
		@include bp('medium') {
			flex-direction: row-reverse;
		}
	}

	&-col {
		@include bp('medium') {
			@include size(50%, auto)
		}
	}

	&-details {
		background-color: $gray-bg;
		@extend %flex-column-center-v;
		@include padding($short: 4rem);

		@include bp('large') {
			@include padding($short: 0 8rem);
		}

	}

	&-title {
		@include margin($bottom: 1rem)
	}

	&-text {
		@include margin($bottom: 3rem)
	}

	&-button {
		border: .1rem solid $orange;
		@include padding($short: 1.5rem 4rem);
		align-self: flex-start;
		@include margin($bottom: 7rem)
	}

	&-link {
		align-self: flex-start;
	}

}