*,
*::before,
*::after {
	box-sizing: border-box
}


body {
	overflow-x: hidden
}

a {
	@extend %outline-accesibility;
}

input,
button,
select,
textarea {
	@extend %outline-accesibility;

}

[tabindex] {
	@extend %outline-accesibility;
}



a {
	color: inherit;
	text-decoration: none;
}

//remove default styles
input,
button,
select,
textarea {
	border: .1rem solid $gray-light;
	//font-size: inherit;
	appearance: none;
	@include margin($short: 0);
	@include padding($short: 0);
	font-family: inherit;
	outline: 0;
	background: transparent;
}

select {

	//remove IE default arrow
	&::-ms-expand {
		display: none;
	}

	//applies rules to the value of a text or password <input> control or the content of a <select> control. 
	&::-ms-value {
		background: none;
		color: $gray-darker //change color according to project
	}
}

//remove clear buttom from IE
input {
	&::-ms-clear {
		width: 0;
		height: 0;
	}

	//applies rules to the value of a text or password <input> control or the content of a <select> control. 
	&::-ms-value {
		background: none;
		color: $gray-darker //change color according to project
	}

	// &[type=checkbox],
	// &[type=file] {
	// 	@extend %hide-element;
	// }

	//remove X from IE
	&[type=search] {

		&::-ms-clear,
		&::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}

		//remove X from Chrome
		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			display: none;
		}
	}
}

strong {
	font-weight: 700;
}

em {
	font-style: italic;
}

// style when selected mouse
::selection {
	background-color: $green;
	color: $white;
}

img {
	display: block;
}

.master-wrapper-content {
	.fvr & {
		@include bp('large') {
			@include padding($top: 0);
		}
	}
}

