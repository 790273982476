.collection {
	&-wrapper {
		@include size(94%, auto);
		@include margin($short: 0 auto 5rem);

		@include bp('large') {
			@include margin($short: 0 auto 10rem);
		}
		&:last-of-type {
			@include margin($short: 0 auto);
		}
	}

	@include bp('large') {
		max-width: 98rem;
	}

	&-row {
		@extend %flex-column-center-h;
		&:first-of-type {
			@include margin($bottom: 5rem);
			@include bp('large') {
				justify-content: center;
				align-items: flex-start;
				width: calc(100% - 51rem);
				@include padding($right: 3rem);
			}
		}
		&:nth-of-type(2) {
			@include bp('large') {
				width: 51rem;
			}
		}
	}

	&-block {
		@include margin($bottom: 6rem);
		@include bp('large') {
			@include margin($bottom: 10rem);
			@include _('flex-wrap');
		}

		&:last-of-type {
			@include margin($bottom: 0);
		}

		&.right {
			.collection-row {
				&:nth-of-type(2) {
					order: -1;
				}
				&:first-of-type {
					@include bp('large') {
						@include padding($left: 3rem, $right: 0);
					}
				}
			}
		}
	}

	&-name {
		@include margin($bottom: 2rem);
	}

	&-description {
		@include margin($bottom: 4rem);
	}

	&-product {
		// border-radius: 50%;
		// overflow: hidden;
		@include margin($short: 0 1.5rem);
		max-width: 27rem;
		max-height: 27rem;

		@include bp('medium') {
			max-width: 46rem;
			max-height: 46rem;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&-banner {
		overflow: hidden;
		@include margin($short: -3rem -3% 3rem);
		max-height: 16rem;
		@include relative;

		@include bp('medium') {
			max-height: 25rem;
		}

		@include bp('large') {
			max-height: 50rem;
			width: calc(100vw + 98rem);
			@include margin($short: -5rem calc(0% - (50vw - 49rem)) 10rem);
		}

		&::after {
			content: '';
			@include absolute(bottom -.1rem left 50%);
			transform: translateX(-50%);
			@include size(101%);
			background: url('../../FvrAssets/img/banner-clip.svg') center bottom / contain no-repeat;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&-text {
		text-align: center;
	}

	.swiper {
		&-container {
			@include size(100%, auto);
		}
		&-button {
			&-prev {
				@include size(3rem);
				background-color: $green;
				border-radius: 50%;
				left: 0;

				@include bp('medium') {
					@include size(5rem);
					left: calc(50% - 25.5rem);
				}
				&::after {
					background: url('../../FvrAssets/img/arrow.svg') center / contain no-repeat;
					transform: rotate(180deg);
					content: '';
					@include size(1.5rem);
				}
			}
			&-next {
				@include size(3rem);
				background-color: $green;
				border-radius: 50%;
				right: 0;
				@include bp('medium') {
					@include size(5rem);
					right: calc(50% - 25.5rem);
				}
				&::after {
					background: url('../../FvrAssets/img/arrow.svg') center / contain no-repeat;
					content: '';
					@include size(1.5rem);
				}
			}
		}

		&-slide {
			@extend %flex-center;
			max-width: 100%;
		}
	}
	&-title {
		.page-title.product-name & {
			.fvr & {
				width: 100%;
				display: inline-block;
				@include bp('extra-large') {
					text-align: left;
				}
			}
		}
	}
}