.footer {
	&-wrapper {
		&.fvr {
			@include padding($short: 0);
		}
	}

	.fvr & {
		@include padding($short: 0);
		background-color: transparent;
	}

	&-block {
		.list {
			a {
				color: $green;
			}
			@include bp('large') {
				text-align: left;
			}
		}

		.fvr & {
			@include margin($short: 0 0 5rem);
			width: 100%;
			@extend %flex-column-center-h;
			@include bp('medium') {
				width: 50%;
				@include margin($short: 0);
			}

			@include bp('large') {
				width: calc(100% / 3);
				align-items: flex-start;
			}
		}

		&:last-of-type {
			.fvr & {
				@include margin($bottom: 0);
			}
		}

		&.footer-logo {
			.fvr & {
				@include bp('medium') {
					width: 100%;
					@include margin($short: 0 0 5rem);
				}
				@include bp('large') {
					width: calc(100% / 3);
				}
			}
		}

		.title {
			.fvr & {
				color: $green;
				@include bp('large') {
					width: 100%;
					text-align: left;
				}
			}
		}
	}

	&-middle {
		.fvr & {
			@include margin($short: 0);
		}

		.center {
			.fvr & {
				@extend %flex-center-h;
				@include padding($short: 3rem 3rem 5rem);

				@include bp('large') {
					@include padding($short: 9rem 3rem);
				}
			}
		}
	}

	&-lower {
		.fvr & {
			@include padding($short: 4rem);
			background-color: $green-lighter;
		}
	}

	&-text {
		.fvr & {
			color: $green;
		}
	}

	&-logo {
		@include bp('medium') {
			flex-direction: row;
			justify-content: center;
		}
		img {
			max-width: 15rem;
			@include bp('large') {
				max-width: 22rem;
			}
		}
	}

	&-social {
		@include margin($top: 3rem);
		@extend %flex-center;

		&-item {
			@include margin($right: 1rem);
			&:last-of-type {
				@include margin($right: 0);
			}
		}
	}
}