.item {
	&-box {
		button {
			&::before {
				.fvr & {
					background-image: none;
					background-position: center;
				}
			}
			&.product-box-add-to-cart-button {
				&::before {
					background-image: url('../../FvrAssets/img/bag-02.svg');
				}
			}
			&.add-to-wishlist-button {
				&::before {
					background-image: url('../../FvrAssets/img/wishlist-02.svg');
				}
			}
			&.add-to-compare-list-button {
				&::before {
					background-image: url('../../FvrAssets/img/list.svg');
				}
			}
		}

		.quick-view-button {
			&::before {
				background-image: url('../../FvrAssets/img/search-02.svg');
				background-position: center;
			}
		}

		.buttons {
			border-radius: .5rem;
		}

		.nosidebar & {
			width: 100%;
			@include margin($left: 0, $bottom: 4rem);

			@include bp('medium') {
				width: 32%;
				@include margin($bottom: 6rem);
			}

			@include bp('extra-large') {
				width: calc((100% / 4) - 2.25rem);
				@include margin($right: 3rem);
			}
		}

		&:nth-child(2n+1) {
			.nosidebar & {
				@include bp('extra-large') {
					@include margin($left: 0)
				}
			}
		}

		&:nth-child(3n + 1) {
			.nosidebar & {
				@include bp('medium') {
					clear: none;
				}
			}
		}

		&:nth-child(3n+2) {
			.nosidebar & {
				@include bp('medium') {
					@include margin($left: 2%);
				}
				@include bp('extra-large') {
					@include margin($left: 0%);
					clear: none;
				}
			}
		}

		&:nth-child(3n+3) {
			.nosidebar & {
				@include bp('medium') {
					@include margin($left: 2%);
				}
				@include bp('extra-large') {
					@include margin($left: 0%);
					clear: none;
				}
			}
		}

		&:nth-child(4n+4) {
			.nosidebar & {
				@include bp('extra-large') {
					@include margin($right: 0);
				}
			}
		}
	}
}