.newsletter {
	background-color: $green;
	@include padding($short: 2.5rem 0);

	&-subscribe {
		@extend %flex-center;

		&-text {
			@include padding($short: 1.5rem 3rem);
			@include size(100%, auto);
			&::placeholder {
				.fvr & {
					text-transform: none;
				}
			}

		}
		&-button {
			.fvr & {
				color: $green;
			}
		}
	}

	&-email {
		.newsletter-subscribe-text {
			.fvr & {
				color: $white;
				background-color: $green;
				height: 4rem;
				width: 100%;
				text-align: center;
				@include bp('medium') {
					height: 100%;
					text-align: left;
				}
			}
			&:focus {
				.fvr & {
					color: $white;
				}
			}
		}
		.newsletter-subscribe-button {
			.fvr & {
				background: none;
				border: none;
				position: static;
				height: 4rem;
				width: 100%;
				border-radius: .5rem .5rem 0 0;
				@include padding($short: .5rem 3rem);
				@include bp('medium') {
					position: absolute;
					@include size(auto, 100%);
					@include padding($short: 1.5rem 3rem);
					border-radius: .5rem 0 0 .5rem;
				}
			}
		}
		.fvr & {
			background-color: transparent;
			border-radius: .5rem;
			border: .2rem solid $white;
			height: auto;
			@extend %flex-wrap;
			@include padding($short: 0);
			@include margin($bottom: 1rem);
			@include bp('medium') {
				height: 5rem;
				display: block;
				@include padding($short: 0 7rem 0 0);
			}
		}
	}

	&-result {
		color: $white;
		display: none;
	}

	.title {
		.fvr & {
			text-transform: none;
			color: $white;
		}
	}

	.fvr & {
		@include padding($short: 2rem 2.5rem)
	}

	&-consent {
		&.inputs {
			@include size(100%, auto);
			white-space: normal;
			@include bp('large') {
				max-width: 56rem;
				@include margin($short: 0 auto);
			}
			input[type='checkbox'] {
				@include size(1.2rem);
				background: $white;
				border-radius: .2rem;
				@include absolute(top .2rem left 0);
				border: none;
				&:checked {
					&::after {
						@include absolute(top 50% left 50%);
						transform: translate(-50%, -50%) rotate(45deg);
						content: '';
						@include size(.4rem, .7rem);
						border-bottom: .2rem solid $green;
						border-right: .2rem solid $green;
					}
				}
			}
			label {
				text-align: left;
				@include padding($left: 2.5rem);
				.earth-theme.fvr & {
					@include font-size(12);
				}
			}
		}
	}
}