.btn {
	display: inline-flex;
	@include padding($short: 1.5rem 3rem);
	color: $white!important;
	background-color: $green;
	border-radius: .5rem;
}

.button {
	&-1 {
		&.add-to-cart-button {
			border-radius: .5rem;

			&::before {
				.overview & {
					content: none;
				}
			}
		}
		.fvr & {
			border-radius: .5rem;
		}
	}
}