.picture {
	&-thumbs {
		.slick {
			&-next {
				.fvr & {
					@include size(3.4rem);
					border-radius: 50%;
					background: $green url('../../FvrAssets/img/arrow.svg') center / .7rem no-repeat;
					@include margin($top: 0);
					transform: translateY(-50%);
					@include bp('medium') {
						right: calc(50% - 25rem);
						@include size(5rem);
					}
					@include bp('extra-large') {
						right: calc(50% - 23.5rem);
					}

					@include bp('wide') {
						right: calc(50% - 26rem);
						@include size(5rem);
					}
				}
			}

			&-prev {
				.fvr & {
					@include size(3.4rem);
					border-radius: 50%;
					background: $green url('../../FvrAssets/img/arrow.svg') center / .7rem no-repeat;
					@include margin($top: 0);
					transform: translateY(-50%) rotate(180deg);
					@include bp('medium') {
						left: calc(50% - 25rem);
						@include size(5rem);
					}
					@include bp('extra-large') {
						left: calc(50% - 23.5rem);
					}

					@include bp('wide') {
						left: calc(50% - 26rem);
						@include size(5rem);
					}
				}
			}

			&-slider {
				.fvr & {
					@include bp('medium') {
						width: 36rem;
					}
				}
			}
		}

		.gallery & {
			.fvr & {
				@include margin($short: 2rem 0 0);
			}
		}

		img {
			.gallery & {
				.fvr & {
					max-width: none;
					max-height: none;
					@include size(100%, auto);
				}
			}
		}
	}

	&-thumb {
		.cloudzoom-gallery {
			@include relative;
			&::after {
				content: '';
				@include absolute(top 50% left 50%);
				transform: translate(-50%, -50%);
				@include size(calc(100% + 1.6rem));
				border: .1rem solid transparent;
				border-radius: 50%;
				transition: .2s;

				@include bp('medium') {
					@include size(calc(100% + 1.8rem));
					border: .2rem solid transparent;
				}
			}
			&.cloudzoom-gallery-active {
				&::after {
					border: .1rem solid $green;
					@include bp('medium') {
						border: .2rem solid $green;
					}
				}
			}
		}
		.fvr & {
			border-radius: 50%;
		}
		a {
			.fvr & {
				border-radius: 50%;
				overflow: visible!important;
			}
			.gallery & {
				.fvr & {
					border: .8rem solid $white;
				}
			}
		}
		// img {
		// 	border-radius: 50%;
		// 	overflow: hidden;
		// }
		.gallery & {
			.fvr & {
				@include bp('medium') {
					border: .2rem solid transparent;
				}
			}
		}
	}

	&-link {
		// .fvr & {
		// 	border-radius: 50%;
		// 	overflow: hidden;
		// }

		img {
			opacity: 1;
		}
	}
}