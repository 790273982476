.header {
	&-logo {
		a {
			.fvr & {
				max-width: 15rem;
				@include bp('large') {
					max-width: 16.5rem;
					@include padding($left: 1.5rem);
				}
			}
		}
	}

	&-links {
		li {
			&::before {
				.fvr & {
					background-position: center!important;
					background-color: transparent!important;
				}
			}
		}
		#registerlink {
			&::before {
				.fvr & {
					background-image: url('../../FvrAssets/img/register.svg');
				}
			}
		}
		#loginlink,
		#logoutlink {
			&::before {
				.fvr & {
					background-image: url('../../FvrAssets/img/login-g.svg');
				}
			}
		}
		#myaccountlink {
			&::before {
				.fvr & {
					background-image: url('../../FvrAssets/img/user-02-g.svg');
				}
			}
		}
	}
	&-options {
		.select {
			&-wrap {
				.select-box {
					border: .2rem solid $green;
					border-radius: .5rem;
				}
			}
			&-inner {
				color: $green;
			}
		}
	}

	&-selectors {
		&-wrapper {
			select {
				.fvr & {
					min-width: 14rem;
				}
			}

			> div {
				.fvr & {
					@include margin($short: .8rem 1.6rem);
				}
			}
		}
	}
}