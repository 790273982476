.fieldset {
	.fvr & {
		@include margin($short: 0 0 5rem);
	}
	.title {
		.fvr & {
			text-align: center;
		}
	}

	.form-fields {
		.fvr & {
			background-color: transparent;
			border-top: none;
		}

		.gender {
			> span {
				> {
					label {
						border: none;
						@include padding($short: 0 1rem 0 3.5rem);
						line-height: 4.5rem;
					}
				}
			}
		}

		.inputs {
			@include margin($short: 0 auto 2rem);
			@include size(100%, auto);
			input {
				&[type='text'],
				&[type='email'],
				&[type='password'] {
					border: none;
					background-color: $gray-lighter;
					@include size(100%, 5rem);
					@include padding($short: 1rem 2rem);
					@include font-size(16);
					@include bp('large') {
						@include font-size(20);
					}
				}
				&[type='radio'] {
					@include size(2.4rem);
					border-radius: 50%;
					border: .2rem solid $green;
					@include absolute(top 50% left 0);
					transform: translateY(-50%);
					&:checked {
						&::after {
							@include absolute(top 50% left 50%);
							transform: translate(-50%, -50%);
							content: '';
							@include size(1rem);
							background-color: $gray-light;
							border-radius: 50%;
						}
					}
				}
				&[type='checkbox'] {
					@include size(2.4rem);
					border: .2rem solid $green;
					@include relative;
					border-radius: .5rem;
					&:checked {
						&::after {
							@include absolute(top 50% left 50%);
							transform: translate(-50%, -50%) rotate(45deg);
							content: '';
							@include size(.8rem, 1.3rem);
							border-bottom: .3rem solid $green;
							border-right: .3rem solid $green;
						}
					}
				}
			}

			.required {
				border-color: transparent;
				border-width: 0;
				@include font-size(16);
				color: $green;
				@include margin($short: 0);
				@include absolute(top 1rem right .5rem);
			}

			.select {
				&-wrap {
					.select-box {
						border: .2rem solid $green;
						border-radius: .5rem;
					}
				}
			}

			textarea {
				border: none;
				background-color: $gray-lighter;
				@include size(100%, 5rem);
				@include padding($short: 1rem 2rem);
			}
			label {
				@include font-size(16);
				@include bp('large') {
					@include font-size(20);
				}
			}
			&-checkbox {
				@include size(100%, auto);
				white-space: normal;

				input[type='checkbox'] {
					@include absolute(top .2rem left 0);
					border-radius: 0;
					border: .1rem solid $gray;
					@include size(1.2rem);
					&:checked {
						&::after {
							@include absolute(top 50% left 50%);
							transform: translate(-50%, -50%) rotate(45deg);
							content: '';
							@include size(.4rem, .7rem);
							border-bottom: .2rem solid $green;
							border-right: .2rem solid $green;
						}
					}
				}
				label {
					@include padding ($left: 2rem);
				}
				.consent-error-msg {
					display: none;
				}
			}
		}
	}

	.inner-wrapper {
		.fvr & {
			background-color: transparent;
			border-top: none;
		}
	}
}