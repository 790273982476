.breadcrumb {
	.fvr & {
		width: 100%;
		@include margin($short: 0);
		background-color: $gray-lighter;
		color: $green;
	}
	a {
		.fvr & {
			color: $green;
		}
	}
	strong {
		.fvr & {
			color: $green!important;
			font-weight: 700;
		}
	}
}