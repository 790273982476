.block {
	&-account {
		&-navigation {
			.fvr & {
				margin: 4rem auto!important;
				background-color: transparent;
			}

			.title {
				.fvr & {
					background-color: transparent;
					border: .2rem solid $green;
					border-radius: .5rem;
					color: $green;
				}
			}

			.list {
				a {
					border-radius: .5rem;
				}
			}
		}
	}
}