.mega-menu {
	&.fvr-menu-horizontal {
		@include bp('large') {
			@include _('flex-wrap');
		}

		.has-sublist {
			&.active {

				a,
				span {
					@include relative;

					&::after {
						@include absolute(bottom -3.4rem left 50%);
						transform: translateX(-50%);
						content: '';
						@include size(0);
						border-left: .8rem solid transparent;
						border-right: .8rem solid transparent;
						border-bottom: 1.3rem solid $white;
					}
				}
			}
		}

		.row {
			@extend %flex-center-h;
		}

		.categories {
			.picture {
				height: 100%;

				img {
					border-radius: .5rem;
				}
			}
		}

		.box {
			@include padding($short: .5rem 1.5rem)
		}

		.boxes {

			&-1,
			&-2,
			&-3,
			&-4,
			&-5,
			&-6 {
				.box {
					max-width: 18rem;
				}
			}
		}

		.dropdown {
			&.active {
				box-shadow: 0 2rem 2rem -2rem rgba($black, .3);
			}
		}
	}

	&.fvr-menu-sidebar {

		.dropdown {
			.fvr & {
				@include bp('large') {
					position: static;
					transform: none;
				}

				.row-wrapper {
					@include bp('large') {
						@include padding($short: 2rem 0);
					}
				}
			}

			&.categories {
				.picture {
					height: auto;

					img {
						max-height: 15rem;
					}
				}
			}
		}

		.with-subcategories {
			cursor: pointer;

			span {
				cursor: pointer;
			}

			&.single-item-categories {
				@include relative;

				&::before {
					content: '';
					@include absolute(top 50% left -10rem);
					@include size(0, .4rem);
					@include margin($short: -.2rem 0 0);
					background-color: $green;
					transition: .2s;
				}

				&:hover {
					&::before {
						width: 8rem;
					}
				}
			}
		}
	}

	.dropdown {
		.fvr & {
			width: 100%;
			transform: translateX(-50%);
			@include margin($short: 0);
			border-top: 0;
			top: 8rem;
		}
	}
}

.earth-theme.variant-2 .header-menu>ul li>a::before {
	background-color: $green !important;
}