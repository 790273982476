.page {
	&-title {
		.fvr.earth-theme & {
			@include margin($short: 0);
			@include padding($short: 0 0 3rem);
			@include bp('large') {
				@include size(100%, auto);
				background: none;
				@include relative;
			}
		}
		h1 {
			&::after {
				.fvr & {
					display: none;
				}
			}
			.fvr & {
				text-align: left;
				color: $green;
				@include bp('large') {
					@include relative;
					bottom: 0;
				}
			}
			.fvr.manufacturer-page-body &,
			.fvr.category-page-body & {
				text-align: center;
			}

			.account-page & {
				.fvr & {
					text-align: center;
				}
			}
		}
		&.product-name {
			h1 {
				.fvr & {
					text-align: center;
					@include bp('extra-large') {
						text-align: left;
					}
				}
			}
			.fvr & {
				@include padding($short: 0 0 4rem);
			}
		}
		// .contact-page & {
		// 	.fvr.earth-theme & {
		// 		@include margin($short: 5rem 0 0);
		// 	}
		// }
	}
	.fvr & {
		text-align: left;
	}

	&-banner {
		@include margin($short: 0 -3% 5rem);
		overflow: hidden;
		max-height: 16rem;
		@include relative;
		&::after {
			content: '';
			@include absolute(bottom -.1rem left 50%);
			transform: translateX(-50%);
			@include size(101%);
			background: url('../../FvrAssets/img/banner-clip.svg') center bottom / contain no-repeat;
		}

		@include bp('medium') {
			max-height: 25rem;
		}
		@include bp('large') {
			max-height: 50rem;
			width: 100vw;
			@include margin($short: 0 calc(0% - (50vw - 49rem)) 5rem);
		}

		@include bp('wide') {
			@include margin($short: 0 calc(0% - (50vw - 64rem)) 5rem);
		}

		.topic-page-body & {
			@include bp('wide') {
				@include margin($short: 0 calc(0% - (50vw - 49rem)) 5rem);
			}
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&-body {
		a {
			color: $green;
		}

		.change-password-page & {
			.fvr & {
				background-color: transparent;
			}
		}
	}

	&.login-page {
		.inner-wrapper {
			.fvr & {
				background-color: transparent;
				border-top: none;
			}
		}
	}
}