.overview {
	.quantity {
		.fvr & {
			@include bp('extra-large') {
				@include margin($short: 0 3rem 0 0);
				text-align: center;
			}
		}
		.decrease,
		.increase {
			.fvr & {
				@include size(3rem);
				background-color: rgba($green, .5);
				background-image: none;
				border: none;
				color: $white;
				@include font-size(20);
			}
		}

		.qty-input {
			.fvr & {
				border: .2rem solid $green;
				border-radius: .5rem;
			}
		}
	}
	&-buttons {
		div {
			input {
				.fvr.earth-theme & {
					background-image: none;
					background-color: transparent;
					background-position: left center;
					@include padding($short: 0 0 0 4rem);
				}
				&.add-to-wishlist-button {
					.fvr.earth-theme & {
						background-image: url('../../FvrAssets/img/wishlist-rect.svg');
					}
				}
				&.add-to-compare-list-button {
					.fvr.earth-theme & {
						background-image: url('../../FvrAssets/img/compare-list-rect.svg');
					}
				}
			}
		}

		.fvr & {
			border-width: .1rem 0 0;
			@include bp('medium') {
				width: 70%;
				margin: 0;
				float: left;
				text-align: left;
			}

			@include bp('extra-large') {
				width: 55%;
			}

			@include bp('wide') {
				width: 65%;
			}
		}

		+ .product-social-buttons {
			.fvr & {
				@include bp('medium') {
					@include margin($top: 0);
				}
			}
		}
	}

	.fvr & {
		@include bp('large') {
			float: none;
			width: 100%;
		}
		@include bp('extra-large') {
			float: right;
			width: 48%;
			text-align: left;
		}
	}

	.add-to-cart-panel {
		.fvr & {
			@include bp('extra-large') {
				@include _('flex-center-v');
			}
		}
	}
	.short-description {
		.fvr & {
			border-width: 0;
			@include padding($short: 0);
		}
	}
}