//base-font
$base-font: 62.5%;

//breakpoint vars
$extra-small: 22.5em; //360px
$small: 36em;  //576px
$medium: 48em;  //768px
$large: 64em; //1024px
$extra-large: 75em; //1200px
$wide: 90em; //1440px
$extra-wide: 100em; //1600px

//breakpoints
$breakpoints: (
	'extra-small': $extra-small,
	'small': $small,
	'medium': $medium,
	'large': $large,
	'extra-large': $extra-large,
	'wide': $wide,
	'extra-wide': $extra-wide
);

// Caching current breakpoint
// Not meant to be manually edited
$default-breakpoint: root;
$current-breakpoint: $default-breakpoint;

// Caching existing placeholders
// Not meant to be manually edited
$placeholders: ();

//colors
$black: #000;
$white: #fff;
$orange: #f04c26;
$gray: #5a5758;
$gray-dark: #393939;
$gray-darker: #232323;
$gray-light: #918f8f;
$gray-lighter: #f7f7f7;
$gray-bg: #f7f7f7;
$green: #016266;
$green-lighter: #e5efef;



//fonts
$din: 'DIN 2014';

//z-index
$z-index: (
	'pothole': -1,
	'basement': 0,
	'sidewalk': 1,
	'bungalow': 40,
	'bungalow-chimney': 50,
	'house': 60,
	'tower': 70,
	'skyscrapper': 80,
	'sky': 90,
	'satellite': 100
);