.header {
	&-menu {
		&-inline {
			@include size(auto);
			display: none;
			@include bp('large') {
				display: flex;
				@include _('flex-center');
				flex-grow: 1;
			}
		}
	}
}