html {
	font-size: $base-font;
}

body {
	font-family: $din;
}

//include only the following properties:
// font-family
// font-size
// font-weight
// line-height
// text-decoration
// text-align
// text-transfotm
// color


// blocks sort by order A-Z
body {
	&.earth-theme {
		&.fvr {
			font-family: $din;
			@include font-size(16);
			line-height: line-height(26, 16);

			@include bp('large') {
				@include font-size(20);
				line-height: line-height(30, 20);
			}
			input {
				&[type='email'],
				&[type='button'],
				&[type='submit'] {
					font-family: $din;
				}
			}
		}
	}
	&.fvr {
		color: $gray;
	}
}

.btn {
	@include font-size(14);
	font-weight: 700;
	@include font-tracking(140);
	text-transform: uppercase;
}

.footer {
	&-block {
		.title {
			@include font-size(16);
			line-height: line-height(22, 16);
			text-transform: uppercase;
		}
	}

	&-disclaimer {
		color: $white;
		@include font-size(12);
		line-height: line-height(17, 12);
		@include font-tracking(.86);

		span {
			color: $orange;
		}
	}
}

.header {
	&-menu {
		&-inline {
			@include font-size(16);
		}
	}
}

.media {
	&-details {
		color: $gray-darker;
	}

	&-title {
		@include font-size(45);
		font-weight: 800;
		line-height: line-height(61, 45);
	}

	&-text {
		opacity: .75;
		line-height: line-height(28, 16);
		@include font-size(16);
	}

	&-link {
		text-transform: uppercase;
		opacity: .5;
		font-weight: 700;
		line-height: line-height(19, 14);
		@include font-size(16);
		@include font-tracking(160);

		&-small {
			@include font-tracking(100);
			@include font-size(14)
		}
	}

	&-button {
		color: $orange;
		@include font-size(14);
		line-height: line-height(19, 14);
		@include font-tracking(140);
		font-weight: 700;
		text-transform: uppercase;
	}
}

.newsletter {
	&-email {
		font-weight: 700;
		@include font-size(14);
		line-height: line-height(19, 14);
		@include font-tracking(140);
	}

	&-subscribe {
		&-text {
			color: $orange;

			&::placeholder {
				text-transform: uppercase;
				color: currentColor;
				font-weight: 700;
			}
		}

		&-button {
			text-transform: uppercase;
			color: $white;
			font-weight: 700;
		}
	}

	.title {
		color: $green;
		@include font-size(20);
		line-height: line-height(33, 24);
	}

	&-consent {
		label {
			color: $white;
		}
	}
}

.earth-theme {
	&.fvr {
		.newsletter {
			&-email {
				input {
					&.newsletter-subscribe-text {
						@include font-size(14);
						font-weight: 400;
						&::placeholder {
							font-weight: 400;
						}
					}
					&.newsletter-subscribe-button {
						@include font-size(14);
						@include font-tracking(140);
						font-weight: 700;
						&::placeholder {
							font-weight: 700;
						}
					}
				}
			}
		}

		.footer-text {
			@include font-size(12);
		}

		.footer {
			.title {
				strong {
					@include font-size(20);
					font-weight: 600;
				}
			}
		}

		.page-title {
			h1 {
				@include font-size(30);
				font-weight: 700;
				line-height: line-height(40, 30);

				@include bp('large') {
					@include font-size(45)
				}
			}
		}
		.page-body {
			line-height: line-height(24, 16);
			@include bp('large') {
				@include font-size(20);
				line-height: line-height(30, 20);
			}
		}

		.item {
			&-box {
				.product-title,
				.actual-price {
					@include bp('large') {
						@include font-size(20);
					}
				}
				.actual-price {
					font-weight: 600;
				}
			}
		}

		.topic-block-body {
			@include bp('large') {
				line-height: line-height(30, 20);
			}
		}

		.overview {
			input {
				&.qty-input {
					color: $green;
					font-weight: 700;
				}
			}
			&-buttons {
				div {
					input {
						@include font-size(12);
						font-weight: 700;
						color: $green;
						text-transform: uppercase;
					}
				}
			}

			.short-description {
				@include bp('large') {
					@include font-size(20);
					line-height: line-height(30, 20);
				}
			}
		}

		.ui {
			&-tabs {
				.ui-tabs-nav {
					li {
						a {
							font-weight: 700;
						}
					}
				}
				.ui-state {
					&-active {
						&.ui-tabs-active {
							a {
								color: $gray;
							}
						}
					}
					&-default {
						a {
							color: $gray-light;
						}
						color: $gray-light;
					}
				}
				.ui-tabs-panel {
					p {
						line-height: line-height(30, 20);
					}
				}
			}
		}

		.product {
			&-details {
				&-page {
					.product-price {
						font-weight: 600;
						@include bp('large') {
							@include font-size(30)
						}
					}
				}
			}
		}
		.prices-volume {
			color: $green;
			font-weight: 600;
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

.collection {
	&-title {
		@include font-size(12);
		line-height: line-height(17, 12);
		font-weight: 600;
		color: rgba($green, .5);
		text-transform: uppercase;

		@include bp('large') {
			@include font-size(14);
		}
	}
	&-name {
		@include font-size(30);
		line-height: line-height(35, 30);
		font-weight: 700;
		color: $green;
		text-transform: uppercase;
		@include bp('large') {
			@include font-size(35);
		}
	}
	&-description {
		@include font-size(16);
		line-height: line-height(24, 16);
		color: rgba($green, .7);
		@include bp('large') {
			@include font-size(20);
		}
	}
	&-text {
		@include font-size(16);
		color: $gray;
		line-height: line-height(26, 16);

		@include bp('large') {
			@include font-size(20);
			line-height: line-height(30, 20);
		}
	}
}

.button {
	&-1 {
		&.add-to-cart-button {
			.overview & {
				font-weight: 700;
				@include font-tracking(140);
			}
		}
	}
}

.theme {
	&-collection {
		&-title {
			@include font-size(12);
			font-weight: 700;
			text-transform: uppercase;
			color: rgba($green, .5);
			@include font-tracking(86);

			@include bp('large') {
				@include font-size(14);
			}
		}
		&-name {
			@include font-size(30);
			font-weight: 700;
			text-transform: uppercase;
			color: $green;
			@include font-tracking(86);
			@include bp('large') {
				@include font-size(35);
			}
		}
		&-description {
			@include font-size(16);
			font-weight: 400;
			line-height: line-height(24, 16);
			color: rgba($green, .7)
		}

		.slider {
			&__pagination {
				&-text {
					@include font-size(12);
					@include font-tracking(12);
				}
			}
		}
	}
	&-dropdown {
		&-title {
			@include font-size(20);
			line-height: line-height(22, 20);
			opacity: .75;
			@include bp('large') {
				@include font-size(24);
				line-height: line-height(28, 24);
			}
		}
		&-content {
			strong {
				font-weight: 700;
				color: $green!important;
			}
			a {
				font-weight: 700;
				color: $green;
				text-decoration: underline;
			}
		}
	}
	&-block {
		&-title {
			@include font-size(25);
			line-height: line-height(32, 25);
			color: $green;
			font-weight: 700;
			text-transform: uppercase;

			@include bp('large') {
				@include font-size(35);
			}
		}
		&-text {
			@include font-size(16);
			line-height: line-height(24, 16);
			@include bp('large') {
				@include font-size(20);
			}
		}

		a {
			font-weight: 600;
			text-transform: uppercase;
			@include font-size(16);
		}
	}
}

.block {
	&-account {
		&-navigation {
			.title {
				strong {
					.fvr & {
						font-weight: 700;
					}
				}
			}
		}
	}
}

.fieldset {
	> .title {
		strong {
			.fvr & {
				font-weight: 700;
				@include font-size(18);
			}
		}
	}
	.form-fields {
		.inputs {
			&-checkbox {
				&.inputs {
					label {
						@include font-size(12)
					}
				}
			}
			textarea {
				@include font-size(16);
				@include bp('large') {
					@include font-size(20)
				}
			}
		}
	}
}

.product {
	&-price {
		&-label {
			@include font-size(14);
			font-weight: 600;
			color: $green;
			@include bp('large') {
				@include font-size(16);
			}
		}
	}
}