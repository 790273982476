.earth-theme.fvr {
	.desktop-nav-wrapper {
		background-color: $green;
		box-shadow: none;
		@include bp('large') {
			@include _('flex-between');
			align-items: center;
		}
	}

	.header {
		background-color: $green;
	}

	.responsive-nav-wrapper {
		a,
		span {
			background-image: none;
			background-position: center;
			background-color: transparent;
		}
		.menu-title {
			span {
				background-image: url('../../FvrAssets/img/menu-02-g.svg');
				@include bp('large') {
					background-image: url('../../FvrAssets/img/menu-02.svg');
				}
			}
		}
		.search-wrap {
			span {
				background-image: url('../../FvrAssets/img/search-02-g.svg');
				@include bp('large') {
					background-image: url('../../FvrAssets/img/search-02.svg');
				}
			}
		}
		.personal-button {
			span {
				background-image: url('../../FvrAssets/img/user-02-g.svg');
				@include bp('large') {
					background-image: url('../../FvrAssets/img/user-02.svg');
				}
			}
		}
		.wishlist-opener {
			a {
				background-image: url('../../FvrAssets/img/wishlist-02-g.svg');
				@include bp('large') {
					background-image: url('../../FvrAssets/img/wishlist-02.svg');
				}
			}
		}
		.shopping-cart-link {
			a {
				background-image: url('../../FvrAssets/img/bag-02-g.svg');
				@include bp('large') {
					background-image: url('../../FvrAssets/img/bag-02.svg');
				}
			}
		}
		&-parent {
			@include bp('large') {
				width: 24rem;
			}
		}
	}

	.item-box {
		.product-title {
			height: auto;
			text-transform: none;
			@include margin($bottom: 1rem);
		}
		.picture {
			@include margin($bottom: 3rem);
		}
	}

	.newsletter {
		@include margin($short: 0);
		&-email {
			.newsletter-subscribe-button {
				background-color: $white;
			}
		}
	}

	.breadcrumb {
		a {
			color: $green;
		}
		.delimiter {
			color: $green;
		}
	}

	&.variant-2 {
		.mega-menu {
			&.fvr-menu-horizontal {
				.has-sublist {
					> span,
					> a {
						background-color: transparent;
						color: $white;
						@include font-size(16);
						letter-spacing: .2rem;
						text-transform: uppercase;
						line-height: line-height(24, 16);
						@include padding($short: 0 2.5rem 0);
						min-height: 6rem;
						font-weight: 400;
						cursor: pointer;
					}
				}
				.box {
					text-align: center;
				}
			}
			&.fvr-menu-sidebar {
				.has-sublist {
					span {
						color: $green;
					}
				}
				a {
					color: $green;
				}
			}
			&-responsive {
				&.fvr-menu-sidebar {
					.has-sublist {
						span {
							color: $green;
						}
					}
					a {
						color: $green;
					}
				}
			}
		}
		.menu-title-wrapper {
			span {
				background-image: url('../../FvrAssets/img/menu-02.svg');
			}
			.menu-title {
				span {
					background-position: center;
				}
			}
		}
	}

	&.topic-page-body {
		.master-column-wrapper {
			@include bp('wide') {
				width: 98rem;
			}
		}
	}

	.product {
		&-list {
			.quick-view-button {
				&::before {
					background-position: center;
				}
			}

			.item-box {
				button {
					background-color: $green!important;
					&::before {
						background-position: center;
					}
				}
			}
		}
		&-essential {
			.attributes {
				@include margin($short: 0);
				border: none;
				background-color: transparent;
				@include bp('extra-large') {
					@include padding($short: 2.5rem 0);
				}
				dt {
					&:first-of-type {
						display: none;
					}
				}

				input {
					border: none;
				}

				.selected-value {
					.attribute-square {
						&::after {
							content: none;
						}
						&-container {
							border-color: $green;
						}
					}
				}
			}
			.attribute {
				&-squares {
					li {
						@include margin($short: 0);
						@include padding($top: .5rem);
					}
					.attribute-square {
						@include size(1.6rem);
						box-shadow: none;
					}
				}
				&-square {
					&-container {
						@include padding($short: .6rem);
					}
				}
			}
		}
	}

	.pager {
		border-radius: .5rem;
	}

	.topic-block {
		@include bp('wide') {
			width: 98rem;
		}
	}

	.home-page {
		.topic-block-title {
			h2 {
				&::after {
					@include size(0);
				}
			}
		}
	}

	.filter-block {
		a {
			&.toggleControl {
				@include font-size(14);
				line-height: 2rem;
			}
		}
	}

	.block {
		&.block-category-navigation,
		&.block-manufacturer-navigation,
		&.block-recently-viewed-products {
			@include font-size(14);
			line-height: 2rem;
		}
		.title {
			&::before {
				content: none;
			}
		}
	}

	.gallery.sevenspikes-cloudzoom-gallery {
		@include bp('large') {
			float: none;
			width: 100%;
		}

		@include bp('extra-large') {
			float: left;
			width: 48%;
			@include margin($top: -15rem);
		}
	}
	.cloudzoom-zoom-inside {
		border-radius: 50%;
		overflow: hidden;
	}

	.search {
		&-box {
			.search-box-button {
				background-image: url('../../FvrAssets/img/search-02-g.svg');
				background-color: transparent;
			}
			.select-box {
				background-color: transparent;
			}
		}
	}

	.store {
		&-search-box {
			form {
				border-color: rgba($gray, .5);
			}
		}
	}
	.product-details-page {
		.overview {
			.prices {
				@include margin($bottom: 0);
				&-volume {
					@include margin($bottom: 4.5rem);
					@include bp('medium') {
						@include margin($bottom: 3rem);
					}
					@include bp('large') {
						@include margin($bottom: 4rem);
					}
				}
			}
		}
	}
	&.no-scroll.show-scrollbar {
		.master-wrapper-content {
			z-index: inherit;
		}
	}
	.mfp {
		&-figure {
			&::after {
				box-shadow: none;
				background: transparent;
			}
		}
	}
	.contact-page {
		form {
			background-color: transparent;
			width: 100%;
			@include padding($short: 0);
			label {
				text-align: left;
			}
			// .fieldset {
			// 	.form-fields {
			// 		.inputs {
			// 			input[type='text'],
			// 			input[type='email'],
			// 			input[type='password'] {
			// 				border: none;
			// 			}
			// 		}
			// 	}
			// }
		}
		.topic-block {
			@include padding($short: 0);
			&-body {
				text-align: left;
				a {
					color: $gray;
					text-decoration: none;
				}
			}
		}
		.buttons {
			border-top: none;
		}
	}
}

.theme {
	&-slider {
		&-round {
			@include relative;
			max-height: 16rem;
			overflow: hidden;
			@include margin($bottom: 5rem);

			@include bp('medium') {
				max-height: 25rem;
			}
			@include bp('large') {
				max-height: 50rem;
				@include margin($bottom: 13rem);
			}
			&::after {
				content: '';
				@include absolute(bottom -.1rem left 50%);
				transform: translateX(-50%);
				@include size(101%);
				background: url('../../FvrAssets/img/banner-clip.svg') center bottom / contain no-repeat;
			}
			img {
				object-fit: cover;
				height: 16rem;
				@include bp('medium') {
					height: 25rem;
				}
				@include bp('large') {
					height: 50rem;
				}
			}
		}
	}
	&-radio {
		&-label {
			display: block;
			@include relative;
			cursor: pointer;
			@include size(3.2rem);
			@include margin($right: 2rem);
			&:last-of-type {
				@include margin($right: 0);
			}
		}
		&-input {
			@include absolute (left -9999rem);
			@include size(100%, auto);

			&:checked {
				+ .theme-radio-check {
					&::after {
						border: .2rem solid $green;
					}
				}
			}
		}
		&-check {
			@include absolute(top 50% left 50%);
			transform: translate(-50%, -50%);
			@include size(1.6rem);
			border-radius: 50%;
			content: '';
			background-color: #939292;
			&::after {
				content: '';
				@include absolute(top 50% left 50%);
				transform: translate(-50%, -50%);
				@include size(3.2rem);
				border-radius: 50%;
				border: .2rem solid transparent;
				transition: .2s;
			}
		}
		&-block {
			@extend %flex-center;
		}
	}

	&-collection {
		@include margin($bottom: 8.5rem);

		@include bp('extra-large') {
			@include margin($top: 4rem, $bottom: 12.5rem);
			@include _('flex-between');
		}

		@include bp('wide') {
			@include margin($top: 10rem);
		}
		&-title {
			@include margin($bottom: .3rem);
		}
		&-name {
			@include margin($bottom: 1.5rem);
		}
		&-description {
			@include margin($bottom: 2.5rem);
			@include size(100%, auto);
			text-align: center;
			@include bp('extra-large') {
				text-align: left;
				flex-grow: 1;
			}
		}

		&-row {
			@extend %flex-column-center-h;

			@include bp('extra-large') {
				width: 48%;
				align-items: flex-start;
			}

			&-desktop {
				display: none;
				@include bp('extra-large') {
					display: block;
					order: -1;
				}
			}
		}

		&-img {
			@include size(47rem);
			@include relative;
			// border-radius: .5rem;
			overflow: hidden;
			@include margin($short: 0 auto);

			&::after {
				content: '';
				display: block;
				@include padding($bottom: 100%);
			}

			img {
				@include absolute;
				@include size(100%);
				object-fit: cover;
			}
		}

		.swiper {
			&-container {
				width: 100%;
				@include padding($bottom: 13rem);
				@include margin($bottom: 4rem);
				max-width: 60rem;

				@include bp('extra-large') {
					@include padding($bottom: 0)
				}
				&-horizontal {
					.swiper-pagination-bullets {
						bottom: 0;
						@include bp('extra-large') {
							display: flex;
							flex-wrap: wrap;
						}
					}
				}
			}
			&-button {
				&-prev {
					@include size(3rem);
					left: 0;
					top: calc(100% - 6rem);
					@include bp('medium') {
						@include size(5rem);
					}
					@include bp('extra-large') {
						display: none;
					}
					&::before {
						background: $green url('../../FvrAssets/img/arrow.svg') center / .7rem no-repeat;
						transform: rotate(180deg);
						content: '';
						@include size(3rem);
						border-radius: 50%;
						@include z-index('sidewalk');
						@include bp('medium') {
							background: $green url('../../FvrAssets/img/arrow.svg') center / .9rem no-repeat;
							@include size(5rem)
						}
					}
					&::after {
						content: '';
						@include size(8rem, 14rem);
						background: linear-gradient(90deg, rgba($white, 0) 0%, rgba($white, 1) 30%);;
						@include absolute(top -3rem left 50%);
						transform: translateX(-50%) rotate(180deg);
						@include bp('medium') {
							content: none;
						}
					}
				}
				&-next {
					@include size(3rem);
					right: 0;
					top: calc(100% - 6rem);
					@include bp('medium') {
						@include size(5rem);
					}
					@include bp('extra-large') {
						display: none;
					}
					&::before {
						background: $green url('../../FvrAssets/img/arrow.svg') center / .7rem no-repeat;
						content: '';
						@include size(3rem);
						border-radius: 50%;
						@include z-index('sidewalk');
						@include bp('medium') {
							background: $green url('../../FvrAssets/img/arrow.svg') center / .9rem no-repeat;
							@include size(5rem)
						}
					}
					&::after {
						content: '';
						@include size(8rem, 14rem);
						background: linear-gradient(90deg, rgba($white, 0) 0%, rgba($white, 1) 30%);
						@include absolute(top -3rem left 50%);
						transform: translateX(-50%);
						@include bp('medium') {
							content: none;
						}
					}
				}
			}
			&-slide {
				@include relative;
				width: 100%;

				&::after {
					content: '';
					display: block;
					@include padding($bottom: 100%);
				}
				&-container {
					@include absolute;
					@include size(100%);
					border-radius: .5rem;
					overflow: hidden;
				}
			}
			&-pagination {
				margin: 0 auto;
				@include bp('small') {
					max-width: 36rem;
				}
				@include bp('extra-large') {
					text-align: left;
					max-width: 100%;
					position: relative;
				}
				&-bullet {
					background: none;
					opacity: 1;
					&::after {
						content: '';
						@include size(8.5rem);
						@include absolute(top 0 left 50%);
						transform: translateX(-50%);
						border: .2rem solid transparent;
						border-radius: 50%;
						transition: .2s;

						@include bp('extra-large') {
							@include size(12.5rem);
							transform: translateX(calc(-50% - .1rem));
						}
					}
					&-active {
						&.swiper-pagination-bullet {
							background: none;
							@include relative;
							&::after {
								border: .2rem solid $green;
							}
						}
					}
				}

				&-bullets {
					&-dynamic {
						.swiper-pagination-bullet {
							transform: scale(1);
							@include bp('small') {
								transform: scale(1);
							}
							&-active {
								&-prev,
								&-next,
								&-prev-prev,
								&-next-next {
									transform: scale(1);
									@include bp('small') {
										transform: scale(1);
									}
								}
							}
						}
					}
				}
			}
		}

		.slider {
			&__pagination {
				@include size(8.5rem, auto);
				text-align: center;
				@include relative;

				@include bp('extra-large') {
					@include size(12.5rem, auto);
				}

				&-img {
					&-wrapper {
						@include size(6.5rem);
						overflow: hidden;
						border-radius: 50%;
						@include margin($short: 1rem 1rem 1.5rem);
						@include relative;

						@include bp('extra-large') {
							@include size(10.5rem);
						}
					}
				}
			}
		}
	}
	&-dropdown {
		.topic-page & {
			list-style: none;
			@include margin($short: 0 0 5rem);
			@include padding($short: 0)
		}
		&-item {
			@include padding($short: 1.5rem 0);
			border-bottom: .1rem solid $gray-light;
			@include bp('medium') {
				@include padding($short: 2rem 0);
			}
			@include bp('large') {
				@include padding($short: 3rem 0);
			}
		}
		&-title {
			@include relative;
			min-height: 4rem;
			@extend %flex-center-v;
			.open & {
				color: $green;
				font-weight: 600;
			}
			&::after {
				content: '';
				@include absolute(top 50% right 0);
				transform: translateY(-50%);
				@include size (2rem);
				background: url('../../FvrAssets/img/plus.svg') center / contain no-repeat;

				.open & {
					background: url('../../FvrAssets/img/minus-g.svg') center / contain no-repeat;
				}
			}
		}
		&-content {
			@include size(100%, auto);
			@include padding($short: 1.5rem 0 0);
			display: none;
			overflow: hidden;

			@include bp('medium') {
				@include padding($short: 2rem 0 0);
			}
			@include bp('large') {
				@include padding($short: 3rem 0 0);
			}
		}
	}

	&-video {
		@include size(100%, auto);
		display: block;
		&-wrapper {
			@include size(100%, auto);
			@include margin($short: 3rem 0);
			@include relative;
			border-radius: .5rem;
			overflow: hidden;
		}
		&-play {
			@extend %position-center;
			background-color: fade-out($white, .75);
			@include size(6.5rem);
			border: .4rem solid $white;
			@include border-radius(50%);

			@include bp('medium') {
				@include size(8.5rem);
			}

			&::after {
				content: '';
				@include size(0);
				border-style: solid;
				border-width: 1.5rem 0 1.5rem 2.33rem;
				border-color: transparent transparent transparent $white;
				display: block;
				@extend %block-center;
			}
		}
		&-pause {
			@include absolute(bottom -5rem left 0 right 0);
			@include size(100%, 5rem);
			z-index: z-index('sidewalk');
			background: linear-gradient(to top, fade-out($gray, .5) 0%, fade-out($white, .9) 35%, fade-out($white, .9) 100%);
			opacity: 0;
			span {
				@extend %position-center-v;
				background-color: fade-out($white, .75);
				border: .1rem solid $white;
				@include border-radius(50%);
				@include size(2.5rem);
				color: $white;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				font-family: monospace;
				@include font-size(9);
			}
		}
	}

	&-block {
		@include size(100%, auto);
		@include margin($bottom: 8rem);
		@include bp('medium') {
			@include _('flex-between-center');
			@include margin($bottom: 5rem);
		}
		@include bp('large') {
			@include margin($bottom: 10rem);
		}
		&-image {
			width: 80%;
			@include margin($short: 0 auto 4rem);
			//border-radius: 50%;
			overflow: hidden;
			@include relative;

			@include bp('medium') {
				width: 100%;
				@include margin($short: 0 auto);
			}

			&::after {
				content: '';
				display: block;
				@include padding($bottom: 100%);
			}

			img {
				@include absolute;
				display: block;
				@include size(100%);
			}
		}
		&-link {
			@include margin($right: 2rem);
		}
		a {
			.page-body & {
				.topic-page & {
					.fvr & {
						text-decoration: none;
					}
				}
			}
		}
		&-text {
			@include margin($bottom: 2rem);
		}
		&-row {
			@include bp('medium') {
				@include size(48%, auto)
			}
			&:last-of-type {
				.right & {
					@include bp('medium') {
						order: -1;
					}
				}
			}
		}
		&-title {
			@include bp('large') {
				@include margin($bottom: 2rem);
			}
		}
	}

	&-modal {
		@include fixed(top 0 left 0 right 0 bottom 0);
		background: rgba($black, .5);
		@include size(0);
		overflow-x: hidden;
		//overflow-y: scroll;
		@extend %flex-center;
		z-index: 1100;

		&.open {
			@include size(100%, auto);
			min-height: 100vh;
		}

		&-wrapper {
			@include relative;
			@include padding($short: 5rem);
			background: $white;
			border-radius: .5rem;
			@include size(100%, auto);
			max-width: 80%;
			@include bp('medium') {
				max-width: 60rem;
			}
		}

		&-btn {
			@include absolute(top .5rem right .5rem);
			@include size(4rem);
			background: #ccc url('../../Content/img/close.png') center no-repeat;
			cursor: pointer;
			border-radius: 50%;
		}

	}
}